// start content

@import 'shared';
@import 'reset';

@font-face {
    font-family: 'OpenDyslexic';
    src: url('fonts/opendyslexic-regular-webfont.woff2') format('woff2'),
         url('fonts/opendyslexic-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body, html{
  font-size: 14px;
  background: $background;
  color: $text;
  font-family: 'OpenDyslexic', sans-serif;
  font-weight: 400;
}

body{
  margin: calc((100vh - 568px)/2) calc((100% - 320px)/2);
  padding: $page_padding $page_padding 0.25rem $page_padding;
  box-sizing: border-box;
  border-left: 1px solid;
  border-right: 1px solid;
  line-height: $line_height;

  &#video{
    border: none;
    margin: 1rem 0;
    &:before,
    &:after{
      display: none;
    }
    h1{
      text-align: center;
      margin-bottom: 3rem;
    }
    video{
      width: 100%;
      height: 80vh;
      object-fit: contain;
      &:focus{
        outline: none;
      }
    }
  }

  &:after,
  &:before{
    content: '';
    position: fixed;
    height: calc((100vh - 568px)/2);
    left: calc((100% - 320px)/2);
    right: calc((100% - 320px)/2);
    background: $background;
  }
  &:before{
    top: 0;
    border-bottom: 1px solid;
  }
  &:after{
    bottom: 0;
    border-top: 1px solid;
  }
}

h1,
p{
  margin-bottom: 1em;
}

h2{
  width: calc(100% + #{$page_padding * 2});
  background-color: $h2_background;
  margin-left: -$page_padding;
  margin-bottom: $page_padding;
  padding: $page_padding/2 $page_padding;
  box-sizing: border-box;
}

h3{
  margin-top: -2 * $page_padding;
  width: calc(100% + #{$page_padding * 2});
  background-color: $h2_background;
  margin-left: -$page_padding;
  margin-bottom: $page_padding;
  box-sizing: border-box;
  padding: $page_padding/2 $page_padding;
  text-align: left;
  font-size: $small_type;
}

hr{
  box-shadow: none;
  outline: none;
  border-width:0;
  height: 1px;
  background-color: $text;
  margin-bottom: $page_padding;
}

a{
  color: inherit;
}
ul{
  margin: 0 0 $page_padding 0;
}

li{
  list-style: circle;
  margin-left: $page_padding;
}

img{
  display: block;
  width: 100%;
}

@media (max-width: 767px){
  body{
    margin: $page_padding;
    padding: $page_padding  ;
    &:after,
    &:before{
      content: '';
      position: fixed;
      height: $page_padding;
      left: $page_padding;
      right: $page_padding;
      background: $background;
    }
    &:before{
      top: 0;
      border-bottom: 1px solid;
    }
    &:after{
      bottom: 0;
      border-top: 1px solid;
    }
  }
}